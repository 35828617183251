import {Injectable} from "@angular/core";
import {ApiService} from "../../../@core/services/api.service";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "@core/types/data/user";
import {Business} from "@core/types/data/business";
import {catchError, map} from "rxjs/operators";
import {Router} from "@angular/router";
import {NgxZendeskWebwidgetService} from "ngx-zendesk-webwidget";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // -----------------------------------------------------------------------------------------------------
  // @ private
  // -----------------------------------------------------------------------------------------------------

  private _user: BehaviorSubject<User> = new BehaviorSubject(null);
  private _currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _business: BehaviorSubject<Business> = new BehaviorSubject(null);

  private _businesses: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  get businesses$(): Observable<any[]> {
    return this._businesses.asObservable();
  }

  get currentUser$(): Observable<any> {
    return this._currentUser.asObservable();
  }

  get currentBusiness$(): Observable<Business> {
    return this._business.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Get the config
  get user(): Observable<User> {
    return this._user.asObservable()
  }

  // Get the config
  get business(): Observable<Business> {
    return this._business.asObservable()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _apiService: ApiService,
    private router: Router,
    private _ngxZendeskWebwidgetService: NgxZendeskWebwidgetService
  ) {
    this.fetchBusinesses();
    this.fetchCurrentUser();
  }

  fetchBusinesses() {
    this._apiService.make('auth', 'businessView', {}, {})
      .then((response) => {
        if (response) {
          this._businesses.next(response);
        } else {
          // Handle empty response or authentication failure
          console.error('Invalid response');
        }
      })
      .catch((err) => {
        // Handle other errors
        console.error('Error during API call:', err);
      });
  }

  checkIfRedirect() {
    if (this._currentUser.value) {
      if (this._business.value) {
        if (this.router.url == '/authentication/business-selection' || window.location.pathname == '/') {
          this.router.navigate(['/home']);
        }
      } else {
        if (this.router.url == '/') {
          this.router.navigate(['/authentication/business-selection']);
        }
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  fetchCurrentUser(redirectHome = false) {
    this._apiService.make('auth', 'account', {}, {})
      .then((response: any) => {
        if (response && response.user) {
          const userData: { surname: any; name: any; id: any; email: any } = {
            id: response.user.id,
            email: response.user.email,
            name: response.user.name,
            surname: response.user.surname
          };
          this._currentUser.next(userData);
          if (response.business) {
            if (response.business?.network?.origin) {
              /*if (!window.location.origin.includes("localhost") && window.location.origin != response.business.network.origin) {
                window.location.href = response.business.network.origin;
              }*/
            }
            this._ngxZendeskWebwidgetService.initZendesk()
              .then((success) => {
                console.log('Zendesk loaded', success);
                this._ngxZendeskWebwidgetService.zE('webWidget', 'identify', {
                  name: [response.user.name, response.user.surname].join(' '),
                  email: response.user.email,
                  phone: response.user.phone,
                });

                this._ngxZendeskWebwidgetService.zE('webWidget', 'prefill', {
                  name: {
                    value: [response.user.name, response.user.surname].join(' '),
                    readOnly: true
                  },
                  email: {
                    value: response.user.email,
                    readOnly: true
                  },
                  phone: {
                    value: response.user.phone || "",
                    readOnly: true // optional
                  }
                });
                this._ngxZendeskWebwidgetService.zE('webWidget', 'show');
              })
            this._business.next(response.business);
          }
        }
        this.checkIfRedirect();
      })
      .catch((err) => {
        if (err.status == 401) {
          this._currentUser.next(err.error.user);
        }
        this.checkIfRedirect();
      });
  }


  postBusinessCode(code: string) {
    return this._apiService.make('auth', 'identity', {}, {body: {id: code}})
      .then((response) => {
        if (response) {
          this.fetchCurrentUser(true)
          // Assuming response contains user data after identification
          this._user.next(response);
        } else {
          // Handle empty response or authentication failure
          console.error('Invalid response');
        }
      })
      .catch((err) => {
        // Handle other errors
        console.error('Error during API call:', err);
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  businesses() {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'businessView', {}, {})
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            // Handle authentication failure or empty response
            reject('Invalid response');
          }
        })
        .catch((err) => {
          // Handle other errors
          reject('Error during API call');
        });
    });
  }

  signUp(name: string, surname: string, email: string, phone: string, username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'signup', {}, {
        body: {
          name,
          surname,
          email,
          phone,
          username,
          password
        }
      })
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject('Invalid credentials');
          }
        })
        .catch((err) => {
          // Handle other errors
          reject('Error during authentication');
        });
    });
  }

  signin(username: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'signin', {}, {body: {username, password}})
        .then((response) => {
          if (response) {
            if (!response.avatar) {
              response.avatar = '/assets/images/avatars/profile.jpg';
            }
            this._user.next(response as User);
            this.fetchCurrentUser();
            this.fetchBusinesses();
            // this.checkIfRedirect();
            this.router.navigate(['/authentication/business-selection']);
            resolve();
          } else {
            // Authentication failed
            reject('Invalid credentials');
          }
        })
        .catch((err) => {
          this.checkIfRedirect()
          // Handle other errors
          reject('Error during authentication');
        });
    });
  }

  isAuthenticated(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'account', {}, {})
        .then((response: any) => {
          const status = response ? 200 : 404;
          const user = response ? response.user : null;
          const business = response ? response.business : null;
          resolve({status, user, business});
        })
        .catch((error: any) => {
          reject({status: 401});
        });
    });
  }

  checkUsernames(username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'checkUsername', {username}, {})
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            // Authentication failed
            reject('Invalid credentials');
          }
        })
        .catch((err) => {
          // Handle other errors
          reject('Error during authentication');
        });
    });
  }

  signout(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'signout', {}, {})
        .then((response) => {
          if (response) {
            this._currentUser.next(null);
            this._business.next(null);
            this.checkIfRedirect();
            resolve();
          } else {
            // Signout failed
            reject('Invalid credentials');
          }
        })
        .catch((err) => {
          // Handle other errors
          reject('Error during signout');
        });
    });
  }

  businessRegistration(name, vat, fiscalCode, pec, uniqueCode, location, address): Promise<void> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'businessRegister', {}, {
        body: {
          name,
          vat,
          fiscalCode,
          pec,
          uniqueCode,
          location,
          address
        }
      })
        .then((response) => {
          if (response) {
            this._business.next(response as Business);
            this.checkIfRedirect();
            resolve();
          } else {
            // Authentication failed
            reject('Invalid credentials');
          }
        })
        .catch((err) => {
          // Handle other errors
          reject(err);
        });
    });

  }

  ConfirmAlert(id: string , alerts:any[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'confirmAlert', {}, {
        body: {
          id,
          alerts
        }
      })
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject('Invalid credentials');
          }
        })
        .catch((err) => {
          reject('Error during authentication');
        });
    });
  }

  getWalletHistory(wallet: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._apiService.make('auth', 'walletHistory', {wallet}, {})
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject('Invalid credentials');
          }
        })
        .catch((err) => {
          reject('Error during authentication');
        });
    });
  }

}
