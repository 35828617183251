<div class="row full-width">
  <div class="navbar-header d-xl-flex align-items-center d-none pl-2 col-md-4">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row">
      <li class="nav-item d-flex">
        <a class="navbar-brand" [routerLink]="['/home']">
        <span class="brand-logo">
           <img [src]="themeLogoService.getThemeLogo()" alt="brand-logo"/>
        </span>
          <!--          <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>-->
        </a>
      </li>
      <li class="nav-item dropdown-cart mr-1">
        <div class="address-badge">
          <a class="badge badge-pill badge-primary wallet-pill"
             (click)="modalOpenPrimary(modalAddressess, $event)" href="" *ngIf="loaded | async">
            <i data-feather="map-pin" class="wallet-plus2"></i>
            <span class="wallet-info2">
          <p class="m-0 p-0 wallet-title">{{ address.name || address.street + ' ' + address.number }}</p>
              {{ address.zip + ' (' + address.province + ') - ' + address.city }}
        </span>

          </a>
        </div>
      </li>
    </ul>
    <!--/ Navbar brand -->

  </div>


  <div class="search-box col-lg-3 col-xxl-4 d-xl-flex d-none align-items-center">
    <div class="suggestion-container full-width">
      <div class="input-group">
        <input
          #searchInput
          type="text"
          class="form-control"
          placeholder="Cerca un codice o una descrizione"
          aria-label="Cerca un codice o una descrizione"
          aria-describedby="basic-addon-search2"
          [(ngModel)]="textSearch"
          (ngModelChange)="searchUpdate()"
          (keyup.enter)="search(searchInput.value)"
        />
        <div class="input-group-append" id="button-plate">
          <button class="btn btn-primary" type="button" rippleEffect (click)="search(searchInput.value)">CERCA</button>
        </div>
      </div>
      <div class="card dropdown2" *ngIf="showDropdownList && suggestedProducts.length > 0">
        <ul class="suggested-products">
          <ng-container *ngFor="let product of suggestedProducts">
            <li  (click)="suggestionClick(product.id)">
              <img [src]="product.image" alt="{{ product.name }}"/>
              <span>{{ product.id }} - {{ product.name }}</span>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="card dropdown2" *ngIf="showDropdownList && suggestedProducts.length == 0">
        <div class="p-2 py-15">Nessun suggerimento disponibile.<br>Premi cerca per avviare la ricerca</div>
      </div>
    </div>
    <div class="custom-control custom-checkbox ml-1 cursor-pointer">
      <input type="checkbox" class="custom-control-input" id="customCheck1" [(ngModel)]="codeSearch"/>
      <label class="custom-control-label text-white cursor-pointer" for="customCheck1">Ricerca esatta</label>
    </div>
  </div>

  <div class="navbar-container d-flex content col-lg-12 col-xl-5 col-xxl-4">
    <div class="bookmark-wrapper d-flex align-items-center">
      <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
      <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
        <li class="nav-item">
          <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
            <span [data-feather]="'menu'" [class]="'ficon'"></span>
          </a>
        </li>
      </ul>
      <!--/ Menu Toggler -->

      <!-- Bookmark -->
      <!--app-navbar-bookmark></app-navbar-bookmark-->
      <!--/ Bookmark -->
    </div>

    <ul class="nav navbar-nav align-items-center flex-nowrap ml-auto">
      <!-- Language selection -->
      <!--      <li ngbDropdown class="nav-item dropdown dropdown-language">-->
      <!--        <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>-->
      <!--          <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i>-->
      <!--          &lt;!&ndash;span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span&ndash;&gt;-->
      <!--        </a>-->
      <!--        <div ngbDropdownMenu aria-labelledby="dropdown-flag">-->
      <!--          <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">-->
      <!--            <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </li>-->
      <!--/ Language selection -->

      <!-- Toggle skin -->
      <!--li class="nav-item d-none d-lg-block">
        <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
          <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
        </a>
      </li-->
      <!--/ Toggle skin -->


      <li class="nav-item dropdown-cart mr-1" *ngIf="sectionTuleroWallet || sectionCustomWallet">
        <a class="badge badge-pill badge-primary wallet-pill"
           (click)="modalOpenPrimary(modalTuleroDisponibilita, $event)" href="" *ngIf="loaded | async">
        <span class="wallet-info1">
          <p class="m-0 p-0 wallet-title">{{ sectionCustomNameWallet || "TULERO" | uppercase }} WALLET</p>
          {{ wallet.balance | price }}
        </span>
          <i data-feather="plus" class="wallet-plus1"></i>
        </a>
      </li>
      <li class="nav-item nav-search d-xl-none d-flex">
        <!-- Search icon -->
        <a class="nav-link nav-link-search" (click)="fullSearchVisible = !fullSearchVisible">
          <span [data-feather]="'search'" [class]="'ficon'"></span>
        </a>
        <div *ngIf="fullSearchVisible" class="search-bar-mobile-overlay d-block d-xxl-none"
             (click)="fullSearchVisible = !fullSearchVisible"></div>
        <div *ngIf="fullSearchVisible" class="search-bar-mobile d-flex d-xxl-none">
            <div class="input-group">
              <input type="text" class="form-control no-borders  full-height" [class.invalid]="showError" [placeholder]="searchBarText"
                     [aria-label]="searchBarText" aria-describedby="basic-addon-search2" [(ngModel)]="textSearch" (ngModelChange)="searchUpdate()"
                     (keyup.enter)="searchMobile(searchInput.value)" [ngClass]="{'is-invalid': isInvalid}"/>
              <div ngbDropdown class="input-group-append">
                <div ngbDropdown class="btn-group">
                  <button type="button" class="btn btn-outline-primary" rippleEffect (click)="searchMobile(searchInput.value)">{{selectedOption}}</button>
                  <button
                    ngbDropdownToggle
                    type="button"
                    class="btn btn-outline-primary dropdown-toggle-split"
                    rippleEffect
                  >
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div ngbDropdownMenu>
                    <a ngbDropdownItem (click)="changeSelectedOption('codice')">Codice</a>
                    <div class="dropdown-divider"></div>
                    <a ngbDropdownItem (click)="changeSelectedOption('testo')">Testo</a>
                  </div>
                </div>
              </div>
            </div>
          <div class="card dropdown2 dropdown-mobile full-width mt-4" *ngIf="showDropdownList && suggestedProducts.length > 0">
            <ul class="suggested-products">
              <ng-container *ngFor="let product of suggestedProducts">
                <li  (click)="suggestionClick(product.id)">
                  <img [src]="product.image" alt="{{ product.name }}"/>
                  <span>{{ product.id }} - {{ product.name }}</span>
                </li>
              </ng-container>
            </ul>
          </div>
          <div class="card dropdown2 dropdown-mobile full-width mt-4" *ngIf="showDropdownList && suggestedProducts.length == 0">
            <div class="p-2 py-15">Nessun suggerimento disponibile.<br>Premi cerca per avviare la ricerca</div>
          </div>
        </div>
      </li>


      <!--      <div class="search-box col-lg-3 col-xxl-4 d-xl-none d-flex align-items-center">-->
      <!--        <li class="nav-item nav-search d-md-none d-sm-block">-->
      <!--          &lt;!&ndash; Search icon &ndash;&gt;-->
      <!--          <a class="nav-link nav-link-search">-->
      <!--            <span [data-feather]="'search'" [class]="'ficon'"></span>-->
      <!--          </a>-->
      <!--        </li>-->
      <!--        something-->
      <!--      </div>-->

      <!-- Search -->
      <!--      <app-navbar-search></app-navbar-search>-->
      <!--/ Search -->

      <!-- Cart -->
      <app-navbar-cart></app-navbar-cart>
      <!--/ Cart -->

      <!-- Notification -->
      <!--app-navbar-notification></app-navbar-notification-->
      <!--/ Notification -->

      <!-- User Dropdown -->
      <li ngbDropdown class="nav-item dropdown-user">
        <a
          class=" user-nav nav-link dropdown-toggle dropdown-user-link"
          id="dropdown-user"
          ngbDropdownToggle
          id="navbarUserDropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <ng-container *ngIf="loaded | async" class="user-nav">
            <div class="user-nav d-sm-flex d-none">
              <span class="user-name font-weight-bolder">{{ currentBusiness.name }}</span>
              <span class="user-status">{{ currentBusiness.code }}</span>
            </div>
            <span class="avatar"
            ><img
              class="round"
              src="https://cdn0.iconfinder.com/data/icons/shopping-197/48/bl_872_profile_avatar_anonymous_customer_user_head_human-512.png"
              alt="avatar"
              height="40"
              width="40"/><span class="avatar-status-online"></span
            ></span>
          </ng-container>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
          <!--          <a ngbDropdownItem [routerLink]="['/pages/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profilo</a>-->
          <a ngbDropdownItem [routerLink]="['/order']"><span [data-feather]="'shopping-bag'" [class]="'mr-50'"></span>
            Ordini</a>
          <a ngbDropdownItem [routerLink]="['/addresses']"><span [data-feather]="'map-pin'" [class]="'mr-50'"></span>
            Indirizzi</a>
          <a ngbDropdownItem [routerLink]="['/WalletHistory']"><span [data-feather]="'clock'" [class]="'mr-50'"></span>
            Storico </a>
          <a ngbDropdownItem [routerLink]="['/subscription/plans']" *ngIf="rowSection">
            <svg class="prime-icon mr-50" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
              <path fill="currentColor"
                    d="m20.092 14.326l.193-1.894c.103-1.011.17-1.678.117-2.099h.02c.871 0 1.578-.746 1.578-1.666C22 7.747 21.293 7 20.421 7s-1.579.746-1.579 1.667c0 .416.145.797.384 1.089c-.343.223-.792.695-1.468 1.405c-.52.547-.78.82-1.07.863a.835.835 0 0 1-.473-.07c-.268-.124-.447-.462-.804-1.139L13.527 7.25c-.22-.417-.405-.766-.572-1.047c.683-.368 1.15-1.117 1.15-1.98C14.105 2.994 13.163 2 12 2s-2.105.995-2.105 2.222c0 .864.467 1.613 1.15 1.98c-.167.282-.351.631-.572 1.048L8.59 10.816c-.358.676-.537 1.014-.805 1.139a.835.835 0 0 1-.473.07c-.29-.043-.55-.317-1.07-.864c-.676-.71-1.125-1.182-1.468-1.405c.24-.292.384-.673.384-1.09C5.158 7.747 4.45 7 3.578 7C2.708 7 2 7.746 2 8.667c0 .92.707 1.666 1.579 1.666h.019c-.054.42.014 1.088.117 2.099l.193 1.894c.107 1.051.196 2.051.306 2.952h15.572c.11-.9.199-1.901.306-2.952M10.855 22h2.29c2.985 0 4.478 0 5.474-.94c.434-.412.71-1.152.908-2.116H4.473c.198.964.473 1.704.908 2.115C6.377 22 7.87 22 10.855 22"/>
            </svg>

            Prime </a>
          <!--          <a ngbDropdownItem [routerLink]="['/apps/todo']"><span [data-feather]="'bookmark'" [class]="'mr-50'"></span> Preferiti</a>-->
          <a ngbDropdownItem [routerLink]="['/returns-warranties']"><span [data-feather]="'package'"
                                                                          [class]="'mr-50'"></span> Resi</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem [routerLink]="['/faq']"><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a>
          <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'"
                                                      [class]="'mr-50'"></span>
            Logout</a>
        </div>
      </li>
      <!--/ User Dropdown -->
    </ul>
  </div>
</div>


<!-- Modal -->
<ng-template #modalTuleroDisponibilita let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="tulero-wallet-modal">{{ sectionCustomNameWallet || "TULERO" | titlecase }}
      Wallet</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!--  <div class="modal-body" tabindex="0">-->
  <!--    <p>-->
  <!--      Per l’acquisto della disponibilità del tuo Tulero Wallet inviare un bonifico a <b>Matheria srl</b>-->
  <!--    </p>-->
  <!--    <div class="pt-1">-->
  <!--      <ul class="steps-description">-->
  <!--        <li>Beneficiario: <b>Matheria srl</b></li>-->
  <!--        <li>IBAN: <b>IT02M0630546854000140159121 (BANCA CASSA DI RISPARMIO DI SAVIGLIANO SPA)</b></li>-->
  <!--        <li>SWIFT: <b>SARCIT2SXXX</b></li>-->

  <!--        <li>Causale: <b>Acquisto disponibilità</b></li>-->
  <!--        <li>Importo: <b>scelto da voi</b></li>-->

  <!--      </ul>-->
  <!--      <p>-->
  <!--        Vi verrà accreditato l'importo bonificato + 5% di cashback immediato sulla vostra disponibilità-->
  <!--      </p>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="modal-body" tabindex="0">
    <h3 class="px-3 text-center text-primary mt-2">RICARICA IL TUO WALLET E RICEVI UN PREMIO!</h3>
    <p class="px-2 text-center mt-2">Ti verrà accreditato l'importo acquistato insieme al premio. Seleziona la somma e
      scopri l'importo del premio.</p>
    <p class="font-large-2 font-weight-bold text-center mt-3 text-secondary">{{ recharge | price }}</p>
    <p class="text-primary font-medium-5 font-weight-bold text-center mt-2 recharge-perncetage">
      <span>+ {{ recharge * percentage | price }} </span><span [data-feather]="'gift'" [class]="'ml-10'"></span></p>
    <div class="pt-1 px-2">
      <nouislider
        [min]="1000"
        [connect]="'lower'"
        [behaviour]="'tap'"
        [max]="50000"
        [range]="{
        'min': [     1000, 100 ],
        '30%': [   5000,  500 ],
        '40%': [  10000, 1000 ],
        '70%': [  20000, 5000 ],
        'max': [ 50000 ]
    }"
        [(ngModel)]="recharge"
        (change)="rechargeChanged()"
        (ngModelChange)="rechargeChanged()"
        class=" slider-secondary"
      ></nouislider>
    </div>
    <!--    <div class="pt-1">-->
    <!--      <ul class="steps-description">-->
    <!--        <li>Beneficiario: <b>Matheria srl</b></li>-->
    <!--        <li>IBAN: <b>IT02M0630546854000140159121 (BANCA CASSA DI RISPARMIO DI SAVIGLIANO SPA)</b></li>-->
    <!--        <li>SWIFT: <b>SARCIT2SXXX</b></li>-->

    <!--        <li>Causale: <b>Acquisto disponibilità</b></li>-->
    <!--        <li>Importo: <b>scelto da voi</b></li>-->

    <!--      </ul>-->
    <!--      <p>-->
    <!--        Vi verrà accreditato l'importo bonificato + 5% di cashback immediato sulla vostra disponibilità-->
    <!--      </p>-->
    <!--    </div>-->
    <div class="pt-1">
      <p class="px-2 text-center mt-1">Riceverai immeditamente la fattura dell'importo pagato e potrai utilizzare la
        disponibilità quando vorrai.</p>
      <button type="button" rippleeffect="" (click)="modalOpenPrimary2(tokenCartModal, $event)"
              class="btn btn-primary waves-effect waves-float waves-light full-width mt-2 font-medium-1">
        <!--        <span [data-feather]="'shopping-cart'" [class]="'mr-25'"></span>-->
        Ricarica subito!
      </button>
    </div>
  </div>
  <!-- / Modal -->
  <!--  <div class="modal-footer d-flex justify-content-center flex-nowrap">-->
  <!--    <button type="button" class="btn btn-outline-primary full-width" rippleEffect>-->
  <!--      <span>Chatta con un operatore</span>-->
  <!--    </button>-->
  <!--  </div>-->
</ng-template>
<!-- / Modal -->
<ng-template #modalAddressess let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="tulero-address-modal">Indirizzi</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="search-bar col-12">
    <i class="search-icon col-1 fa-align-right" data-feather="search"></i>
    <input class="address-search col-11" type="text" [(ngModel)]="searchTerm" (keyup)="filterAddresses()"
           placeholder="Cerca l'indirizzo...">
  </div>
  <div class="px-2 py-25">
    Per gestire i tuoi indirizzi vai alla sezione <a routerLink="/addresses" (click)="modal.dismiss('Cross click')">Indirizzi</a>
  </div>
  <ul>
    <li *ngFor="let address of filteredAddresses; let i = index">
      <div class="address-info col-12 d-flex flex-column" (click)="changeMainAddress(address)">
        <!--        <div class="actions col-2">-->
        <!--          <i data-feather="trash-2" (click)="deleteAddress(i)" class="trash-icon"></i>-->
        <!--          <i data-feather="edit" (click)="editAddress(i)" class="edit-icon"></i>-->
        <strong class="primary-text col-12">{{ address.title }}</strong>
        <!--        </div>-->
        <div class="address-details col-12">
          <p>{{ address.addressDetail.streetAddress }}</p>
          <p>{{ address.addressDetail.city }} , {{ address.addressDetail.province }}
            , {{ address.addressDetail.CAP }}</p>
        </div>
      </div>

    </li>

  </ul>
  <!-- / Modal -->
</ng-template>

<!-- Modal -->
<ng-template #modalAgreements let-modal>
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white" id="myModalLabel1">Consensi</h4>
  </div>
  <div class="modal-body pb-2" tabindex="0" ngbAutofocus>
    <ng-container *ngFor="let agreement of agreements">
      <div class="single-order">
        <core-card [actions]="['collapse']" [defaultStatus]="{collapseStatus:true}">
          <div class="card-title">
            <div class="custom-control custom-checkbox d-flex justify-content-between">
              <div class="supplier-name">
                <input type="checkbox" class="custom-control-input" id="{{agreement.code}}" value="{{agreement.code}}"
                       (change)="toggleAgreement(agreement, $event)"/>
                <label class="custom-control-label" for="{{agreement.code}}">
                  <h4 class="mb-0">{{ agreement.name | titlecase }}</h4>
                </label>
              </div>
            </div>
          </div>
          <div class="card-body">
            <iframe width="100%" height="100%" frameBorder="0" [src]="agreement.url"></iframe>
          </div>
        </core-card>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" [disabled]="!agreementsSubmit" class="btn btn-primary" (click)="sendAgreements()"
            rippleEffect>
      Accept
    </button>
  </div>
</ng-template>
<!-- / Modal -->
<ng-template #tokenCartModal let-modal class="modal-dialog">
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="tulero-token-cart-modal">CARICARE IL WALLET</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" tabindex="0">
    <h3 class="px-3 text-center text-primary mt-2">CARICARE IL WALLET</h3>
    <div class="modal-card-container">
      <div class="row d-flex flex-row">
        <div class="left-side col-12 col-sm-8 d-flex flex-column">
          <div class="card card-body token justify-content-between">
             <span class="token-amount col-6">
              <div>
                <i class="font-large-1 text-secondary" data-feather="command"></i>
              </div>
              <div class="token-details">
                <span class="text-secondary">RICARICA</span>
                <br>
                <span
                  class="token-amount-value text-secondary">{{ (recharge + (recharge * percentage)) | price }} </span>
              </div>
            </span>

          </div>
          <!--          <div class="discount">-->
          <!--            <div class="text-primary">-->
          <!--              <span [data-feather]="'percent'" [class]="'mr-50'"></span>-->
          <!--              <span>Sconto</span>-->
          <!--            </div>-->
          <!--            <div class="discount">-->
          <!--              <span>Inserisci un codice sconto</span>-->
          <!--              <input type="text" placeholder="codice sconto"-->
          <!--                     aria-label="codice sconto" aria-describedby="basic-addon-search2"-->
          <!--                     class="form-control" ng-reflect-model="">-->
          <!--              <div id="button-plate" class="input-group-append mt-50">-->
          <!--                <button type="button" rippleeffect=""-->
          <!--                        class="discount btn btn-primary" rippleEffect>Inserisci-->
          <!--                </button>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="card-body pt-3 pb-1">
            <div class="card-body-title text-primary">
              <span [data-feather]="'shopping-cart'" [class]="'mr-50'"></span>
              <span>Totale pagamento</span>
            </div>
            <div class="detail text-left mt-1">
              <h3 class="text-secondary mb-0">{{ recharge | price }}</h3>
            </div>
          </div>
        </div>
        <div class="right-side col-12 col-sm-4 d-flex flex-column">
          <!--          <div class="payment">-->
          <!--            <div class="custom-control custom-radio">-->
          <!--              <input #tuleroWallet type="radio" id="tulero-wallet" name="customRadioWallet"-->
          <!--                     class="custom-control-input"-->
          <!--                     [checked]="usingWallet"/>-->
          <!--              <label class="custom-control-label" for="tulero-wallet" (click)="toggleWallet($event)">-->
          <!--                <span>Tulero Wallet</span>-->
          <!--              </label>-->
          <!--            </div>-->
          <!--            <span-->
          <!--              class="cashback-amount badge badge-pill badge-primary align-top">CASHBACK {{wallet.balance | price}}</span>-->
          <!--            <div class="text-center" style="padding-left: 0.8rem;-->
          <!--    padding-right: 0.1rem;">-->
          <!--              <img class="wallet-image" src="/assets/images/payments/tulero-wallet.png" alt="">-->
          <!--            </div>-->
          <!--            &lt;!&ndash;        <span class="text-danger payment-error"><span data-feather="alert-circle" class="mr-50"></span></span>&ndash;&gt;-->
          <!--          </div>-->
          <div class="payments">
            <div class="payment mb-1" [class.payment-disabled]="!payment.active"
                 [class.payment-selected]="payment.selected === true" *ngFor="let payment of payments">
              <div class="custom-control custom-radio" *ngIf="payment.method.driver !== 'DEBT'">
                <input type="radio" id="{{payment.method.id}}" name="customRadio" class="custom-control-input"
                       [disabled]="!payment.method.active" (change)="selectPayment(payment, $event)"
                       [checked]="payment.method.selected"/>
                <label class="custom-control-label" for="{{payment.method.id}}">
                  <span>{{ payment.method.label }}</span>
                </label>
              </div>
              <!--              <span-->
              <!--                class="cashback-amount badge badge-pill badge-primary ml-2 align-top">CASHBACK {{payment.cashback | price}}</span>-->
              <div class="text-center" *ngIf="payment.method.driver !== 'DEBT'">
                <img [src]="payment.method.image" alt="">
              </div>
              <span class="text-danger payment-error" *ngIf="!payment.active && payment.error"><span
                data-feather="alert-circle" class="mr-50"></span>{{ payment.error }}</span>
            </div>
          </div>
          <div class="button-container2">
            <!--              <button type="button" class="full-width btn btn-primary mb-1" (click)="checkout()" rippleEffect>-->
            <!--                <span [data-feather]="'check'" [class]="'mr-50'" *ngIf="!checkoutLoading"></span>-->
            <!--                <div class="spinner-border spinner-border-sm mr-50" role="status" *ngIf="checkoutLoading">-->
            <!--                  <span class="sr-only">Loading...</span>-->
            <!--                </div>-->
            <!--                <span>Conferma</span>-->
            <!--              </button>-->
            <button type="button" class="full-width btn btn-primary" rippleEffect (click)="tokensFastCart()">
              <span [data-feather]="'trash-2'" [class]="'mr-50'"></span>
              <span>Acquista</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>


<ng-template #modalCartNotAvailable let-modal>
  <div class="modal-header bg-primary">
    <h5 class="modal-title text-white" id="cart-notAvailable-modal">Non disponibile</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="col-12">
    <h4 class="text-center pt-2">Prodotti non disponibili</h4>
    <ng-container>
      <div class="single-order" *ngFor="let supplier of notAvailable">
        <core-card [actions]="['collapse']" class="cart-supplier">
          <div class="card-title">
            <div class="d-flex justify-content-between">
              <div class="supplier-name">
                <span class="">
                  <h4 class="mb-0">Magazzino {{ supplier.supplier.code | titlecase }}
                  </h4>
                </span>
              </div>
            </div>
          </div>
          <div class="card-body d-flex flex-row full-width col-12">
            <table class="full-width col-12">
              <thead>
              <tr class="mb-2 d-flex full-width text-center">
                <th class="product-image  col-2">
                  <span></span>
                </th>
                <th class="product-details col-4">
                  <span>Nome</span>
                </th>
                <th class="product-code col-2">
                  <span>Codice</span>
                </th>
                <th class="product-quantity  col-2">
                  <span>Quantità</span>
                </th>
                <th class="product-price  col-2">
                  <span>Prezzo</span>
                </th>

              </thead>
              <tbody>
              <tr class="mb-2 d-flex text-center" *ngFor="let product of supplier.products">
                <td class="product-image col-2">
                  <img [src]="product.image" alt="{{ product.name }}"/>
                </td>
                <td class="product-details col-4">
                  <h4 class="mb-0">{{ product.name }}</h4>
                </td>
                <td class="product-code  col-2">
                  <span>{{ product.code }}</span>
                </td>
                <td class="product-quantity  col-2">
                  <span>{{ product.quantity }}</span>
                </td>
                <td class="product-price  col-2">
                  <span>{{ product.price | price }}</span>
                </td>

              </tr>
              </tbody>
            </table>
          </div>

        </core-card>
      </div>
    </ng-container>
  </div>

  <!-- / Modal -->
</ng-template>
