import { Injectable } from '@angular/core';
import { ApiService } from "../../@core/services/api.service";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  private _cart: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  get cart(): Observable<any> {
    return this._cart.asObservable()
  }

  constructor(private apiService: ApiService) {
  }

  getCart(): Promise<any> {
    return this.apiService.make('sales', 'getCart')
      .then((data: any) => {
        this._cart.next(data);
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  getDiscount(discount: string): Promise<any> {
    return this.apiService.make('sales', 'getDiscount', { discount })
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });

  }

  setAddress(id: string) {
    return this.apiService.make('sales', 'setAddress', { id })
      .then((data: any) => {
        this._cart.next(data);
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  editAddress(id: string, name: string) {
    return this.apiService.make('sales', 'editAddress', { id, name })
      .then((data: any) => {
        this._cart.next(data);
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  applyDiscount(discount) {
    return this.apiService.make('sales', 'applyDiscount', { discount })
      .then((data: any) => {
        this._cart.next(data);
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  removeDiscount() {
    return this.apiService.make('sales', 'removeDiscount')
      .then((data: any) => {
        this._cart.next(data);
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  addAddress(address: any) {
    return this.apiService.make('sales', 'newAddress', {}, { body: address })
      .then((data: any) => {
        this._cart.next(data);
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  checkout(orders, payment) {
    return this.apiService.make('sales', 'checkout', {}, {
      body: {
        suppliers: orders,
        payment
      }
    })
      .then((data: any) => {
        return data;
      })
      .catch((error: any) => {
        console.error('Error fetching brands:', error);
        return [];
      });
  }

  buyPrime(payment: any) {
    return this.apiService.make('sales', 'buyPrime', {}, { body: {payment}})
      .then((data: any) => {
        return data as any;
      })
      .catch((error: any) => {
        console.error('buyWallet:', error);
        return [];
      });
  }
}
