<!-- Footer -->
<div class="bg-secondary text-white">
  <div class="content-wrapper container-xxl overflow-hidden pt-3 pb-3">
    <div class="row">
      <div class="col-md-3 reassurance d-flex mt-1">
        <img src="/assets/images/footer/1.png" alt="Pagamento a 90 giorni">
        <div class="d-inline-block">
          <div class="reassurance-title">Pagamento a 90 gg</div>
          <div class="reassurance-description">Con PausePay</div>
        </div>
      </div>
      <div class="col-md-3 reassurance d-flex mt-1">
        <img src="/assets/images/footer/2.png" alt="Pagamento a 90 giorni">
        <div class="d-inline-block">
          <div class="reassurance-title">Consegna con corriere espresso</div>
          <div class="reassurance-description">Esposto per ogni magazzino</div>
        </div>
      </div>
      <div class="col-md-3 reassurance d-flex mt-1">
        <img src="/assets/images/footer/3.png" alt="Pagamento a 90 giorni">
        <div>
          <div class="reassurance-title">Consegna GRATUITA</div>
          <div class="reassurance-description">Per ordini superiori a 200,00 Euro salvo promozioni attive</div>
        </div>
      </div>
      <div class="col-md-3 reassurance d-flex mt-1">
        <img src="/assets/images/footer/4.png" alt="Pagamento a 90 giorni">
        <div>
          <div class="reassurance-title">Resi GRATUITI</div>
          <div class="reassurance-description">Compila il modulo nel tuo account</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bg-secondary text-white footer-block-divided">
  <div class="content-wrapper container-xxl overflow-hidden d-block justify-content-between pt-4 pb-3 d-md-flex">
    <div class="business-card">
      <img src="/assets/images/logo/logo-white.png" alt="Tulero Business" class="mb-2" width="120">
      <div class="row">
        <div class="col-6 col-md-12">
          <p class="font-italic">Tulero è un marchio di Matheria S.r.l.</p>
          <p>P.IVA: 12342520017</p>
        </div>
        <div class="col-6 col-md-12">
          <p class="mb-25">Sede Legale:</p>
          <p class="mb-25">Corso Svizzera 185 scala F</p>
          <p>10121 Torino (TO)</p>
        </div>
      </div>
    </div>
    <div class="justify-content-between common-links mt-1 d-flex">
      <div class="pl-2 pr-2 d-none d-md-block">
        <h3 class="text-white mb-4">Azienda</h3>
        <dl>
          <dd><a [href]="sectionCustomWhoWeAreLink || 'https://tulero.it/chi-siamo/'">Chi siamo</a></dd>
        </dl>
        <dl>
          <dd><a href="https://tulero.it/contatti/">Contatti</a></dd>
        </dl>
        <dl>
          <dd><a href="/faq">FAQ</a></dd>
        </dl>
        <dl>
          <dd><a href="https://tulero.it/blog/">Blog</a></dd>
        </dl>
      </div>
      <div class="pl-2 pr-2 d-none d-md-block">
        <h3 class="text-white mb-4">Servizi</h3>
        <dl>
          <dd>Supporto Clienti</dd>
        </dl>
        <dl>
          <dd><a href="https://tulero.it/guida-acquisto-tulero/">Guida all'utilizzo</a></dd>
        </dl>
        <dl>
          <dd><a href="/returns-warranties">Resi e Garanzie</a></dd>
        </dl>
      </div>
      <div class="pl-2 pr-2">
        <h3 class="text-white mb-4 mb-sm-1">Sicurezza e Trasparenza</h3>
        <dl>
          <dd><a [href]="sectionCustomTermsLink || 'https://tulero.it/condizioni-generali-di-vendita/'">Condizioni generali di vendita</a></dd>
        </dl>
        <dl>
          <dd><a [href]="sectionCustomLegalLink || 'https://tulero.it/informazioni-legali/'">Informazioni legali</a></dd>
        </dl>
        <dl>
          <dd><a [href]="sectionCustomPrivacyLink || 'https://tulero.it/privacy/'">Privacy Policy</a></dd>
        </dl>
        <dl>
          <dd><a href="https://tulero.it/cookies/">Utilizzo dei cookie</a></dd>
        </dl>
      </div>
    </div>
    <div class="mt-md-1 px-md-15">
      <h3 class="text-white">Iscriviti alla Newsletter</h3>
      <p class="font-italic">Ricevi le nostre offerte e novità</p>
      <form class="">
        <div class="input-group">
          <input
            type="text"
            class="form-control newsletter-email"
            placeholder="Email*"
            aria-describedby="button-addon2"
          />
          <div class="input-group-append" id="button-addon2">
            <button class="btn btn-primary" type="button" rippleEffect>ISCRIVITI</button>
          </div>
        </div>
        <div class="custom-control custom-checkbox newsletter-privacy-policy mt-1">
          <input type="checkbox" class="custom-control-input" id="customCheck2" />
          <label class="custom-control-label text-white" for="customCheck2">
            Acconsento all'utilizzo dei dati forniti per l'invio di
            Newsletter commerciali. Per ulteriori informazioni,
            consulta la nostra <a href="#">Privacy Policy</a>
          </label>
        </div>
      </form>
    </div>
  </div>
  <div class="content-wrapper container-xxl overflow-hidden">
  </div>
</div>
<div class="bg-secondary text-white footer-block-divided d-none d-md-block">
  <div class="content-wrapper container-xxl overflow-hidden pt-2 pb-1">
    <span class="h3 text-white align-top">METODI DI PAGAMENTO</span>
    <div class="payment-methods ml-2">
      <div class="payment-method mr-2">
        <img src="/assets/images/payments/wallet-disponibilita.png" alt="">
        <a class="payment-link d-flex align-items-center justify-content-center" (click)="modalOpenPrimary(modalTuleroWallet)">
          <span>SCORPI DI PIÙ</span>
        </a>
        <!-- Modal -->
        <ng-template #modalTuleroWallet let-modal>
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white" id="tulero-wallet-modal">Wallet</h5>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body border-bottom" tabindex="0">
            Il Wallet o la Disponibilità, è un portafoglio virtuale a disposizione del
            Cliente che può essere utilizzato per l’acquisto dei prodotti sulla
            piattaforma, selezionando ‘usa il tuo wallet’ nel carrello in fase di checkout/acquisto.
          </div>
          <div class="modal-body border-bottom guide-images" tabindex="0">
            <div class="row">
              <div class="col-md-6">
                <div>
                  <h5 class="text-center">NON UTILIZZI IL TUO<br/>WALLET</h5>
                  <img src="/assets/images/payments/wallet/wallet-guide-notactive.png" alt="wallet payment not active">
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <h5 class="text-center">UTILIZZI IL TUO<br/>WALLET</h5>
                  <img src="/assets/images/payments/wallet/wallet-guide-active.png" alt="wallet payment active">
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body" tabindex="0">
            <p>
              Puoi aumentare la disponibilità del tuo wallet:
            </p>
            <div class="pt-1">
              <ul class="steps-description">
                <li>Con l’accredito del Cashback durante l’acquisto di
                  prodotti e/o servizi – Il cashback è un rimborso percentuale
                  che ti verrà accreditato al momento dell’acquisto di prodotti
                  e/o servizi, laddove previsto ed evidenziato nel catalogo prodotti
                </li>
                <li>Acquistando la Disponibilità con i vari metodi di pagamento messi a
                  disposizione dalla piattaforma.
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center flex-nowrap">
            <button type="button" class="btn btn-outline-primary full-width" rippleEffect>
              <span>Chatta con un operatore</span>
            </button>
          </div>
        </ng-template>
        <!-- / Modal -->
      </div>
      <div class="payment-method mr-2">
        <img src="/assets/images/payments/pausepay-transparent.png" alt="">
        <a class="payment-link d-flex align-items-center justify-content-center" (click)="modalOpenPrimary(modalPausePay)">
          <span>SCORPI DI PIÙ</span>
        </a>
        <!-- Modal -->
        <ng-template #modalPausePay let-modal>
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white" id="pausepay-modal">Pause Pay</h5>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body border-bottom" tabindex="0">
            Nel nostro e-commerce puoi pagare con PausePay. Il primo metodo di pagamento online B2B dilazionato, con bonifico a 90 giorni in un'unica soluzione.
            <div class="steps d-flex align-items-center justify-content-between mt-2 pb-3">
              <div class="avatar bg-primary avatar-lg">
                <div class="avatar-content">
                  <span [data-feather]="'shopping-cart'" [class]="'avatar-icon'"></span>
                </div>
                <div class="avatar-title">Check-Out</div>
              </div>
              <div class="icons-divider"></div>
              <div class="avatar bg-primary avatar-lg">
                <div class="avatar-content">
                  <span [data-feather]="'pause'" [class]="'avatar-icon'"></span>
                </div>
                <div class="avatar-title">Bonifico in Pausa</div>
              </div>
              <div class="icons-divider"></div>
              <div class="avatar bg-primary avatar-lg">
                <div class="avatar-content">
                  <span [data-feather]="'package'" [class]="'avatar-icon'"></span>
                </div>
                <div class="avatar-title">Ricevi la merce</div>
              </div>
              <div class="icons-divider"></div>
              <div class="avatar bg-primary avatar-lg">
                <div class="avatar-content">
                  <span [data-feather]="'calendar'" [class]="'avatar-icon'"></span>
                </div>
                <div class="avatar-title">Attendi 90 giorni</div>
              </div>
              <div class="icons-divider"></div>
              <div class="avatar bg-primary avatar-lg">
                <div class="avatar-content">
                  <span [data-feather]="'play'" [class]="'avatar-icon'"></span>
                </div>
                <div class="avatar-title">Addebito</div>
              </div>
            </div>
          </div>
          <div class="modal-body" tabindex="0">
            <div class="pt-1">
              <ol class="steps-description">
                <li>Aggiungi al carrello gli articoli da acquistare</li>
                <li>In fase di check-out/acquisto scegli <b>PausePay</b></li>
                <li>Se è la tua prima volta, scegli la tua banca da utilizzare per il pagamento tramite <b>Open Banking</b></li>
                <li>Autorizza il pagamento irrevocabile dilazionato a 90 giorni</li>
                <li><b>PausePay</b> anticipa l'importo a noi, e tu ricevi subito la merce!</li>
              </ol>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-between flex-nowrap">
            <a type="button" class="btn btn-outline-primary full-width" rippleEffect href="https://sdk-web.pausepay.it/help_desk.html" target="_blank" onclick="window.open(this.href, 'mywin', 'left=20,top=20,width=500,height=890,toolbar=1,resizable=0'); return false;">
              <span>Chatta con un operatore</span>
            </a>
            <a type="button" class="btn btn-outline-primary full-width" rippleEffect href="https://pausepay.it/compra-ora-paga-dopo-90giorni-b2b-bnpl/?_gl=1*u2tqbk*_ga*MjA0NTQ0MTMwNS4xNjgwNjkwNDk3*_ga_6FKD84TJCF*MTY4MzI3NzE2OC4yLjEuMTY4MzI3ODQ0OS4wLjAuMA.." target="_blank">
              <span>Visita il sito di PausePay</span>
            </a>
          </div>
        </ng-template>
        <!-- / Modal -->
      </div>
      <div class="payment-method mr-2">
        <img src="/assets/images/payments/stripe.png" alt="">
        <a class="payment-link d-flex align-items-center justify-content-center" (click)="modalOpenPrimary(modalStripe)">
          <span>SCORPI DI PIÙ</span>
        </a>
        <!-- Modal -->
        <ng-template #modalStripe let-modal>
          <div class="modal-header bg-primary">
            <h5 class="modal-title text-white" id="stripe-modal">Carta di Credito</h5>
            <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body border-bottom guide-images" tabindex="0">
            <div class="col-md-12">
              <div class="p-3">
                <img src="/assets/images/payments/stripe/flusso-carta.png" alt="wallet payment not active">
              </div>
            </div>
          </div>
          <div class="modal-body" tabindex="0">
            <p class="text-center px-3 py-1">
              Accettiamo tutte le principali carte di debito e di credito.<br/>
              Per completare il pagamento online, inserisci i dati della carta di credito o di debito e conferma l'ordine.
            </p>
          </div>
          <div class="modal-footer d-flex justify-content-center flex-nowrap">
            <button type="button" class="btn btn-outline-primary full-width" rippleEffect>
              <span>Chatta con un operatore</span>
            </button>
          </div>
        </ng-template>
        <!-- / Modal -->
      </div>
      <div>
        <span class="small">*le modalità di pagamento possono variare in base alle condizioni contrattuali sottoscritte</span>
      </div>
    </div>
  </div>
</div>
<div class="bg-secondary bg-darken-1 p-1 pl-2 pr-2 text-white">
  <div class="content-wrapper container-xxl overflow-hidden d-flex justify-content-between ">
    <span class="d-flex align-items-center">
      <span>COPYRIGHT &copy; {{ year }}</span>
      <a class="ml-25" href="https://tulero.it" target="_blank">Matheria s.r.l.</a>
      <!--span class="d-none d-sm-inline-block">, {{ 'RIGHTS_RESERVED' | translate }}</span-->
    </span>
    <span class="d-flex align-items-center">
      <span>{{ 'PARTNERSHIP' | translate }}</span>
      <a class="ml-25" href="https://meyler.it" target="_blank">Meyler</a>
    </span>
    <span class="d-flex align-items-center">
      SEGUICI SU
      <a href="https://www.facebook.com/matheria.it" target="_blank" class="btn btn-icon btn-white btn-sm ml-1 p-25"
         rippleEffect>
        <i data-feather="facebook" class="ml-0 text-secondary"></i>
      </a>
      <a href="https://www.linkedin.com/company/tulero/" target="_blank" class="btn btn-icon btn-white btn-sm ml-1 p-25"
         rippleEffect>
        <i data-feather="linkedin" class="ml-0 text-secondary"></i>
      </a>
    </span>
  </div>
</div>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
